import React from "react";
import FileInput from "../../../../components/FileInput";

const CvFormation = ({ values, editing, onChange, lang }) => {
  if (editing) return <CvFormationForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="w-[100px]">{values.logo_url && <img src={values.logo_url} alt="image.png" className="w-full max-h-[500px] object-contain rounded" />}</div>
      <div className="w-full">
        <div className="flex flex-row justify-between">
          <h4 className="font-medium font-poppins text-lg">{values.name[lang]}</h4>
          <h4 className="mr-2 font-poppins whitespace-nowrap text-sm text-gray-800">{values.start_and_end_date}</h4>
        </div>
        <div className="font-medium text-sm mb-1 font-poppins">{values.location[lang]}</div>
      </div>
    </div>
  );
};

const CvFormationForm = ({ values, onChange, lang }) => {
  const handleChange = (e) => {
    const field = e.target.name;
    if (field === "name" || field === "location") {
      onChange({ ...values, [field]: { ...values[field], [lang]: e.target.value } });
    } else {
      onChange({ ...values, [field]: e.target.value });
    }
  };

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <div className="w-[100px]">
        <label htmlFor="image_url">Image</label>
        <FileInput folder="quotes/cv_formations" value={values.logo_url} name="logo_url" onChange={handleChange} />
      </div>
      <div className="flex flex-row justify-between">
        <div>
          <label htmlFor="title">Formation name</label>
          <input className="input" type="text" id="name" name="name" value={values.name[lang]} onChange={handleChange} />
        </div>

        <div>
          <label htmlFor="title">Start & End Date</label>
          <input className="input" type="text" id="start_and_end_date" name="start_and_end_date" value={values.start_and_end_date} onChange={handleChange} />
        </div>
      </div>
      <div>
        <label htmlFor="location">Location</label>
        <input className="input" type="text" id="location" name="location" value={values.location[lang]} onChange={handleChange} />
      </div>
    </div>
  );
};

export default CvFormation;
