import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { LuChevronsRight, LuFile } from "react-icons/lu";
import { IoCubeOutline, IoFileTrayFullOutline, IoInformationCircleOutline, IoChatboxEllipsesOutline } from "react-icons/io5";
import { toPng } from "html-to-image";
import { FaChevronUp, FaChevronDown, FaTrash, FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import api from "../../../services/api";
import Loader from "../../../components/loader";
import Modal from "../../../components/modal";
import MultipleSelectInput from "../../../components/MultipleSelectInput";
import FileInput from "../../../components/FileInput";

import {
  TEXT_BLOCK,
  TEAM_BLOCK,
  TABLE_BLOCK,
  CV_HEADER_BLOCK,
  CV_EXPERIENCES_BLOCK,
  CV_FORMATION_BLOCK,
  CONTACT_BLOCK,
  SIGNATURE_BLOCK,
  CARDS_BLOCK,
  TWO_COLS,
  IMAGE,
  MULTIPLE_IMAGES_BLOCK,
} from "../constant";
import SelectUser from "../../../components/selectUser";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

const Menu = ({ quote, pages, blocks, onQuoteChange, onPagesChange, onBlocksChange, selection, onSelect }) => {
  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState("info");
  const [loading, setLoading] = useState(false);

  const handleAddBlock = async (block) => {
    try {
      const pId = selection.page || pages[pages.length - 1]._id;
      const obj = {
        ...block,
        _id: undefined,
        quote_id: quote._id,
        quote_name: quote.name,
        quote_page_id: pId,
        position: blocks.filter((b) => b.quote_page_id === pId).length,
      };
      const { data: b } = await api.post(`/quote_block`, obj);
      if (!b) return toast.error("new block is null block");

      onBlocksChange([...blocks, b]);

      toast.success("Added block to quote");
    } catch (error) {
      console.error(error);
      toast.error("Error creating block");
    }
  };

  const handleAddTemplate = async (template) => {
    if (loading) return;
    setLoading(true);

    try {
      const newPages = [];
      const newBlocks = [];

      const resPages = await api.post(`/quote_page/search`, { quote_id: template._id });
      for (const page of resPages.data) {
        const obj = {
          quote_id: quote._id,
          quote_name: quote.name,
          position: pages.length + page.position,
          template_id: template._id,
          template_name: template.name,
          format: page.format,
        };
        const { ok, data } = await api.post(`/quote_page`, obj);
        if (!ok) throw new Error("Error creating page");
        newPages.push(data);

        const resBlocks = await api.post(`/quote_block/search`, { quote_page_id: page._id });

        const b = resBlocks.data.map((block) => ({
          ...block,
          _id: undefined,
          quote_id: quote._id,
          quote_name: quote.name,
          quote_page_id: data._id,
        }));

        for (let j = 0; j < b.length; j++) {
          const { ok, data } = await api.post(`/quote_block`, b[j]);
          if (!ok) throw new Error("Error creating block");
          newBlocks.push(data);
        }
      }

      onPagesChange([...pages, ...newPages]);
      onBlocksChange([...blocks, ...newBlocks]);
      toast.success("Added block to quote");
    } catch (error) {
      console.error(error);
      toast.error("Error creating page");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (values) => {
    const newPages = [...pages];
    const index = newPages.findIndex((p) => p._id === selection.page);
    newPages[index] = values;
    onPagesChange(newPages);
  };

  return (
    <div className={`bg-gray-50 flex border-x border-gray-300 h-full ${open ? "w-[30%]" : "w-16"}`}>
      <div className="flex flex-col w-16 items-center border-r border-gray-300">
        <button className="p-6" onClick={() => setOpen(!open)}>
          <LuChevronsRight className={`transform ${open ? "" : "rotate-180"}`} />
        </button>
        <button className={`p-6 ${menu === "info" ? "text-sky-500" : ""}`} onClick={() => setMenu("info")}>
          <IoInformationCircleOutline />
        </button>
        <button className={`p-6 ${menu === "blocks" ? "text-sky-500" : ""}`} onClick={() => setMenu("blocks")}>
          <IoCubeOutline />
        </button>
        <button className={`p-6 ${menu === "comment" ? "text-sky-500" : ""}`} onClick={() => setMenu("comment")}>
          <IoChatboxEllipsesOutline />
        </button>
        <button className={`p-6 ${menu === "templates" ? "text-sky-500" : ""}`} onClick={() => setMenu("templates")}>
          <IoFileTrayFullOutline />
        </button>

        {/* Like this until the block edit is ready  */}
        {/* {selection.block !== null && (
          <button className={`p-6 ${menu === "block" ? "text-sky-400" : ""}`} onClick={() => setMenu("block")}>
            <RiTextBlock />
          </button>
        )}
        {selection.page !== null && ( */}
        {(selection.page !== null || selection.block !== null) && (
          <button className={`p-6 ${menu === "page" ? "text-sky-400" : ""}`} onClick={() => setMenu("page")}>
            <LuFile />
          </button>
        )}
      </div>

      <div className={`${open ? "block flex-1" : "bg-red-200 hidden w-0 opacity-0"} transition-all  max-h-full overflow-auto`}>
        {
          {
            blocks: <BlocksLibrary onClick={handleAddBlock} />,
            templates: <TemplateLibrary onClick={handleAddTemplate} />,
            info: <Info quote={quote} pages={pages} blocks={blocks} setQuote={onQuoteChange} />,
            comment: <Comment quote={quote} pages={pages} setQuote={onQuoteChange} />,
            page: (
              <PageEdit
                quote={quote}
                page={pages.find((p) => p._id === selection.page)}
                pages={pages}
                blocks={blocks.filter((b) => b.quote_page_id === selection.page)}
                onPagesChange={onPagesChange}
                onSelect={onSelect}
              />
            ),
            block: <BlockEdit block={blocks.find((b) => b._id === selection.block)} onChange={() => null} onClose={() => onSelect({ page: null, block: null })} />,
          }[menu]
        }
      </div>
    </div>
  );
};

const PageEdit = ({ quote, page, blocks, onPagesChange, onSelect, pages }) => {
  const [values, setValues] = useState({
    format: page?.format,
    name: page?.name,
    background_image_url: page.background_image_url,
    blocks: blocks.map((block) => ({ ...block, quote_id: undefined, quote_name: undefined, quote_page_id: undefined })),
  });

  useEffect(() => {
    setValues({
      format: page?.format,
      name: page?.name,
      background_image_url: page?.background_image_url,
      blocks: blocks.map((block) => ({ ...block, quote_id: undefined, quote_name: undefined, quote_page_id: undefined })),
    });
  }, [page, blocks]);

  const handlePageMove = async (indexShift) => {
    try {
      const pageIndex = pages.findIndex((p) => p._id === page._id);
      if (pageIndex === -1) throw new Error("Page not found in pages array");

      const newPosition = page.position + indexShift;

      await api.put(`/quote_page/${page._id}`, { position: newPosition });

      const newPages = [...pages];

      for (const p of newPages) {
        if (p._id !== page._id) {
          if (indexShift > 0 && p.position >= page.position && p.position <= newPosition) {
            p.position -= 1;
            await api.put(`/quote_page/${p._id}`, { position: p.position });
          } else if (indexShift < 0 && p.position <= page.position && p.position >= newPosition) {
            p.position += 1;
            await api.put(`/quote_page/${p._id}`, { position: p.position });
          }
        } else {
          p.position = newPosition;
        }
      }
      onPagesChange([...newPages]);
      toast.success("Page moved");

      const pageElement = document.getElementById(`page-${page._id}`);
      if (!pageElement) return;
      pageElement.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      toast.error("Error moving page");
    }
  };
  if (!page) return null;

  const isFirstPage = pages.findIndex((p) => p._id === page._id) === 0;
  const isLastPage = pages.findIndex((p) => p._id === page._id) === pages.length - 1;

  const onBackgroundChange = async (pageId, backgroundUrl) => {
    try {
      await api.put(`/quote_page/${pageId}`, { background_image_url: backgroundUrl[0] || "" });
      const newPages = pages.map((p) => (p._id === pageId ? { ...p, background_image_url: backgroundUrl[0] } : p));
      onPagesChange(newPages);
    } catch (error) {
      toast.error("Error uploading background image");
    }
  };

  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Page {page.position + 1}</label>
      </div>
      <div className="space-y-2 p-4">
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="name">
            Name
          </label>
          <input id="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="input" />
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="bocks">
            Blocks
          </label>
          <div className="space-y-2">
            {values.blocks
              .sort((a, b) => a.position - b.position)
              .map((block, i) => {
                const b = BLOCKS.find((b) => b.type === block.type);
                if (!b) return null;
                return (
                  <button
                    key={i}
                    className="w-full flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white"
                    onClick={() => onSelect({ page: page._id, block: block._id })}>
                    <div className="flex flex-col items-center">
                      {b.icon}
                      <div>{b.label}</div>
                    </div>
                  </button>
                );
              })}
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="position">
            Page position
          </label>
          {!isFirstPage && (
            <button onClick={() => handlePageMove(-1)} className="w-full flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
              <FaChevronUp />
            </button>
          )}
          {!isLastPage && (
            <button onClick={() => handlePageMove(1)} className="w-full flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
              <FaChevronDown />
            </button>
          )}
        </div>
        <div className="flex flex-col wrap">
          <label className="mb-3 text-sm font-semibold">Background image</label>
          <FileInput name="backgroundImage" folder="bgImage" value={values?.background_image_url} onChange={(e) => onBackgroundChange(page._id, e.target.value)} />
        </div>
      </div>
    </>
  );
};

const BlockEdit = ({ block, onChange }) => {
  if (!block) return null;

  const handleSavePageTemplate = async () => {
    toast.info("Not implemented yet");
  };

  return (
    <div className="relative">
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Page {block.position + 1}</label>
      </div>
      <div className="space-y-2 p-4">
        <button className="btn btn-primary" onClick={handleSavePageTemplate}>
          Save as template
        </button>
      </div>
    </div>
  );
};

const BLOCKS = [
  TEXT_BLOCK,
  IMAGE,
  TWO_COLS,
  TEAM_BLOCK,
  TABLE_BLOCK,
  // PORTFOLIO_BLOCK, no need. double column doing the job
  MULTIPLE_IMAGES_BLOCK,
  CV_HEADER_BLOCK,
  CV_EXPERIENCES_BLOCK,
  CV_FORMATION_BLOCK,
  // HEADER_BLOCK, , no need. double column doing the job
  SIGNATURE_BLOCK,
  CARDS_BLOCK,
  CONTACT_BLOCK,
];

const BlocksLibrary = ({ onClick }) => {
  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center ">
        <label className="text-lg font-semibold">Blocks</label>
      </div>

      <div className="grid grid-cols-2 gap-4 p-4">
        {BLOCKS.map((block, i) => (
          <div
            key={i}
            item={block}
            onClick={() => onClick(block)}
            className="flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
            <div className="flex flex-col items-center">
              {block.icon}
              <span className="mt-2 text-sm whitespace-nowrap">{block.label}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const TemplateLibrary = ({ onClick }) => {
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.post("/quote/search", {
          is_template: true,
          limit: 1000,
        });
        setTemplates(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const filteredTemplates = templates.filter(
    (template) => template.name.toLowerCase().includes(searchQuery.toLowerCase()) || (template.category && template.category.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  return (
    <div className="h-full overflow-y-scroll">
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Templates</label>
      </div>

      <div className="p-2 border-b border-gray-300">
        <input
          type="text"
          placeholder="Search templates..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="flex flex-row items-start justify-center flex-wrap gap-2 p-4 w-full">
        {filteredTemplates.map((template, i) => (
          <div key={i} item={template} onClick={() => onClick(template)} className="flex items-center  min-h-[200px]  cursor-pointer hover:text-green-700">
            <div className="flex flex-col items-center relative justify-around h-full w-[118px]">
              {template?.thumbnail ? (
                <img src={template.thumbnail} className="w-[118px] object-cover" />
              ) : (
                <div className="w-[118px] h-[152px] bg-gray-100 flex items-center justify-center" />
              )}

              <span className="mt-2 text-xs  font-bold text-center">
                {template.name} {template.category && " - " + template.category}
              </span>

              <span className="mt-2 text-sm"></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Info = ({ quote, pages, blocks, setQuote }) => {
  const [values, setValues] = useState(quote);
  const [projects, setProjects] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok, data } = await api.post("/project/search", { status: "active" });
        if (!ok) throw new Error("Error fetching projects");
        setProjects(data.projects.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (error) {
        console.error(error);
        toast.error("Error fetching projects");
      }
    };
    fetchData();
  }, []);

  const handleDuplicate = async () => {
    try {
      const { ok, data } = await api.post(`/quote/duplicate`, { ...values });
      if (!ok) throw new Error("Error duplicating quote");
      navigate("/quote");
      toast.success("Quote duplicated");
    } catch (error) {
      console.error(error);
      toast.error("Error duplicating quote");
    }
  };

  const handleSubmit = async () => {
    try {
      if (values.projectId) {
        const project = projects.find((p) => p._id === values.projectId);
        values.projectName = project.name;
        values.projectLogo = project.logo;
      }

      if (!quote.is_template && values.is_template) {
        values.thumbnail = await generateThumbnail(pages);
      }

      const { ok, data } = await api.put(`/quote/${quote._id}`, values);
      if (!ok) throw new Error("Error updating quote");
      setQuote(data);
      toast.success("Quote updated");
    } catch (error) {
      console.error(error);
      toast.error("Error updating quote");
    }
  };

  const handleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this quote?")) return;
      const { ok } = await api.remove(`/quote/${quote._id}`);
      if (!ok) return toast.error("Error while deleting quote");
      toast.success("Quote deleted");
      navigate("/quote");
    } catch (error) {
      console.error(error);
      toast.error("Error while deleting quote");
    }
  };

  const handlePrint = async () => {
    setDownloading(true);
    try {
      const { ok, data } = await api.post(`/quote/${quote._id}/pdf`);
      if (!ok) throw new Error("Error generating PDF");
      setQuote(data);
      toast.success("PDF generated");
      window.open(data.file, "_blank");
    } catch (error) {
      console.error(error);
      toast.error("Failed to generate PDF");
    }
    setDownloading(false);
  };

  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Info</label>
      </div>

      <div className="space-y-2 p-4">
        <input id="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="input !mb-2" />
        <select
          id="category"
          value={values.category}
          onChange={async (e) => {
            const { data } = await api.put(`/quote/${quote._id}`, { category: e.target.value });
            setValues(data);
            toast.success("Category updated");
          }}
          className="input !mb-2">
          <option value="">Select a category</option>
          <option value="PROPAL">Propal</option>
          <option value="QUOTE">Quote</option>
          <option value="CONTRACT">Contract</option>
          <option value="USE_CASE">Use case</option>
          <option value="CV">CV</option>
          <option value="OTHER">Other</option>
        </select>
        <select
          id="lang"
          value={values.lang}
          onChange={async (e) => {
            const { data } = await api.put(`/quote/${quote._id}`, { lang: e.target.value });
            setValues(data);
            toast.success("Language updated");
          }}
          className="input !mb-2">
          <option value="fr">Français - French</option>
          <option value="en">Anglais - English</option>
        </select>
        <label className="text-sm font-semibold" htmlFor="description">
          Description
        </label>
        <textarea id="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} className="input" />
        <div>
          <label className="text-sm font-semibold" htmlFor="assign">
            Assign to
          </label>
          <SelectUser
            value={{ name: values.assigned_user_name, _id: values.assigned_user_id }}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { assigned_user_id: e._id, assigned_user_name: e.name, assigned_user_avatar: e.avatar });
              setValues(data);
              toast.success("Assign updated");
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-start gap-4 py-4">
          <label className="text-sm font-semibold" htmlFor="is_template">
            Template
          </label>
          <input
            type="checkbox"
            id="is_template"
            checked={values.is_template}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { is_template: e.target.checked });
              setValues(data);
              toast.success("Template updated");
            }}
          />
        </div>

        <Tags
          value={values.tags}
          onChange={async (e) => {
            const { data } = await api.put(`/quote/${quote._id}`, { tags: e });
            setValues(data);
            toast.success("Tags updated");
          }}
        />

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_to_review">
            To review
          </label>
          <select
            id="action_to_review"
            value={values.action_to_review}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_to_review: e.target.value });
              setValues(data);
              toast.success("Action to review updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_blog">
            Blog
          </label>
          <select
            id="action_blog"
            value={values.action_blog}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_blog: e.target.value });
              setValues(data);
              toast.success("Action blog updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_sortlist">
            Sortlist
          </label>
          <select
            id="action_sortlist"
            value={values.action_sortlist}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_sortlist: e.target.value });
              setValues(data);
              toast.success("Action sortlist updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_codeur">
            Codeur
          </label>
          <select
            id="action_codeur"
            value={values.action_codeur}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_codeur: e.target.value });
              setValues(data);
              toast.success("Action codeur updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <div className="flex flex-row items-center justify-start gap-4 py-4">
          <label className="text-sm font-semibold" htmlFor="archived">
            Archived
          </label>
          <input
            type="checkbox"
            id="archived"
            checked={values.archived}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { archived: e.target.checked });
              setValues(data);
              toast.success("Archived status updated");
            }}
          />
        </div>

        <button className="blue-btn w-full" onClick={handleSubmit}>
          Update
        </button>
        <button className="blue-btn w-full" onClick={handleDuplicate}>
          Duplicate
        </button>
        <button className="red-btn w-full" onClick={handleDelete}>
          Delete
        </button>
        <button className="blue-btn w-full" disabled={downloading} onClick={handlePrint}>
          {downloading ? <Loader size="small" color="white" /> : "Generate PDF"}
        </button>
        {quote.file && (
          <>
            <a href={quote.file} target="_blank" className="empty-btn w-full" download={`${quote.name.replace(/ /g, "_")}.pdf`}>
              Download PDF
            </a>
            <SendModal quote={quote} />
          </>
        )}
      </div>
    </>
  );
};

const Comment = ({ quote, setQuote }) => {
  const [values, setValues] = useState(quote);
  const [comment, setComment] = useState("");
  const { user } = useSelector((state) => state.Auth);
  const [rating, setRating] = useState(quote.score || 0);
  const [hover, setHover] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (comment.trim()) {
      try {
        const updatedComments = [...values.comments, { text: comment, user_name: user.name, user_avatar: user.avatar, created_at: new Date() }];
        const { data } = await api.put(`/quote/${quote._id}`, { ...values, comments: updatedComments });
        setQuote(data);
        setValues(data);
        setComment("");
        toast.success("Quote updated");
      } catch (error) {
        console.error(error);
        toast.error("Error updating quote");
      }
    } else {
      toast.error("Comment cannot be empty!");
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const updatedComments = values.comments.filter((comment) => comment._id !== commentId);
      const { data } = await api.put(`/quote/${quote._id}`, { ...values, comments: updatedComments });
      setQuote(data);
      setValues(data);
      toast.success("Comment deleted");
    } catch (error) {
      console.error(error);
      toast.error("Error deleting comment");
    }
  };

  const handleRatingChange = async (newRating) => {
    setRating(newRating);
    try {
      const { data } = await api.put(`/quote/${quote._id}`, { score: newRating });
      setValues(data);
      toast.success("Score updated");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update score");
    }
  };

  <div className="space-y-2">
    <label
      className="group text-sm font-semibold cursor-pointer flex"
      htmlFor="score"
      onClick={() => handleRatingChange(0)}
      onMouseEnter={(e) => (e.target.textContent = "Reset")}
      onMouseLeave={(e) => (e.target.textContent = "Score")}>
      Score
    </label>
    <div id="score" className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          type="button"
          className="focus:outline-none"
          onClick={() => handleRatingChange(star)}
          onMouseEnter={() => setHover(star)}
          onMouseLeave={() => setHover(null)}>
          <FaStar size={24} color={star <= (hover || rating) ? "#ffc107" : "#e4e5e9"} />
        </button>
      ))}
    </div>
  </div>;

  return (
    <>
      <div className=" py-2 mb-2">
        <div className="space-y-2 flex items-center justify-center">
          <label
            className="group text-lg font-semibold cursor-pointer flex text-center"
            htmlFor="score"
            onClick={() => handleRatingChange(0)}
            onMouseEnter={(e) => (e.target.textContent = "Reset")}
            onMouseLeave={(e) => (e.target.textContent = "Score")}>
            Score
          </label>
        </div>
          <div id="score" className="flex space-x-1 justify-center py-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                type="button"
                className="focus:outline-none"
                onClick={() => handleRatingChange(star)}
                onMouseEnter={() => setHover(star)}
                onMouseLeave={() => setHover(null)}>
                <FaStar size={24} color={star <= (hover || rating) ? "#ffc107" : "#e4e5e9"} />
              </button>
            ))}
          </div>
      </div>
      <hr />
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Comment</label>
      </div>
      <div className="flex-grow-0">
        <div className="flex flex-col gap-y-1 mb-2">
          {(values.comments || []).map((comment, index) => (
            <div key={comment._id || index} className="flex items-center bg-white p-2 rounded-md">
              <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" alt="User Avatar" />
              <div>
                <div className="flex items-center gap-2 text-xs font-semibold">
                  <div>{comment.user_name}</div>
                  <div className="font-normal text-gray-500" data-tip data-for={`comment-${comment._id}`}>
                    {moment(comment.created_at).fromNow()}
                  </div>
                  <ReactTooltip id={`comment-${comment._id}`}>
                    <span>{new Date(comment.created_at).toLocaleString()}</span>
                  </ReactTooltip>
                </div>
                <div>{comment.text}</div>
              </div>
              <div className="flex-grow" />
              <div>
                <div
                  className="text-xs text-gray-500 ml-4 cursor-pointer border border-gray-300 rounded-md p-1 hover:bg-gray-100"
                  onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      handleDelete(comment._id);
                    }
                  }}>
                  <FaTrash />
                </div>
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mt-2 gap-1 justify-between mx-1">
            <input type="text" className="w-[85%] projectsInput m-0" placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} />
            <button className="btn btn-primary">Add</button>
          </div>
        </form>
      </div>
    </>
  );
};

const Tags = ({ value = [], onChange }) => {
  const TAGS = [
    "fintech",
    "govtech",
    "healthtech",
    "edtech",
    "agritech",
    "sportech",
    "proptech",
    "heritagetech",
    "legaltech",
    "martech",
    "hrtech",
    "metabase",
    "teams",
    "mobile",
    "desktop",
    "extension",
    "ia",
    "urgent",
  ];

  const handleChange = (e) => {
    let arr = [...value];
    const tag = e.target.name;
    if (arr.includes(tag)) {
      arr = arr.filter((t) => t !== tag);
    } else {
      arr = [...arr, tag];
    }
    onChange(arr);
  };

  return (
    <div className="space-y-2 w-full col-span-4">
      <label className="block text-sm font-medium text-gray-700" htmlFor="tags">
        Tags
      </label>
      {TAGS.map((tag) => (
        <div className="flex items-center mb-4" key={tag}>
          <input type="checkbox" id={tag} name={tag} checked={value.includes(tag)} onChange={handleChange} className="rounded" />
          <label className="ml-2 text-sm font-medium text-gray-700" htmlFor={tag}>
            {tag}
          </label>
        </div>
      ))}
    </div>
  );
};

const SendModal = ({ quote }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    recipients: [],
    cc: [],
    subject: `Quote ${quote.name}`,
    message: "",
    attachment: quote.file,
  });

  const handleSendingQuote = async () => {
    try {
      if (!values.attachment) {
        toast.error("Please upload a PDF file");
        return;
      }
      const { ok } = await api.post(`/quote/send/${quote._id}`, values);
      if (!ok) throw new Error("Error sending quote");
      toast.success("Quote sent");
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Error sending quote");
    }
  };

  return (
    <>
      {/* <button className="blue-btn w-full" onClick={() => setOpen(true)}>
        Send quote
      </button> */}
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
        <div className="p-8 space-y-6">
          <h2 className="text-lg font-semibold">Save quote as template</h2>
          <div className="space-y-4">
            <MultipleSelectInput value={values.recipients} label="To" placeholder="Emails" onChange={(e) => setValues({ ...values, recipients: e })} />

            <MultipleSelectInput value={values.cc} label="CC" placeholder="Emails" onChange={(e) => setValues({ ...values, cc: e })} />

            <div className="space-y-2">
              <label className="text-sm font-semibold" htmlFor="subject">
                Subject
              </label>
              <input id="subject" value={values.subject} onChange={(e) => setValues({ ...values, subject: e.target.value })} className="input" />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-semibold" htmlFor="message">
                Message
              </label>
              <textarea rows={10} id="message" value={values.message} onChange={(e) => setValues({ ...values, message: e.target.value })} className="input" />
            </div>
          </div>
          <button className="blue-btn w-full" onClick={handleSendingQuote}>
            Send
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Menu;

const generateThumbnail = async (pages) => {
  try {
    const firstPageId = `page-${pages[0]._id}`;
    const firstPageElement = document.getElementById(firstPageId);

    if (!firstPageElement) {
      console.error("First page element not found");
      toast.error("First page element not found");
      return "";
    }

    let dataUrl = "";
    try {
      dataUrl = await toPng(firstPageElement);
    } catch (error) {
      console.error("Error creating thumbnail for template:", error);
      toast.error("Error creating thumbnail for template");
      return "";
    }

    if (!dataUrl) return "";

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = async () => {
        const MAX_WIDTH = 400;
        let scaleSize = 1;

        if (img.width > MAX_WIDTH) {
          scaleSize = MAX_WIDTH / img.width;
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width * scaleSize;
        canvas.height = img.height * scaleSize;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const resizedDataUrl = canvas.toDataURL("image/jpeg");

        try {
          const { data } = await api.post("/file", {
            file: {
              rawBody: resizedDataUrl,
              name: `quote_template_${new Date().toISOString()}`,
            },
            folder: "quote_template",
          });

          resolve(data);
        } catch (error) {
          console.error("Error uploading thumbnail:", error);
          toast.error("Error uploading thumbnail");
          reject(error);
        }
      };

      img.onerror = (error) => {
        console.error("Error loading image for thumbnail:", error);
        toast.error("Error loading image for thumbnail");
        reject(error);
      };
    });
  } catch (error) {
    console.error("Error creating template:", error);
    toast.error("Error creating template");
    return "";
  }
};
