import { useState } from "react";
import { IoTrash } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";

import RichTextEditorSun from "../../../../components/RichTextEditor";

const Cards = ({ values, editing, onChange, lang }) => {
  if (editing) return <CardsForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className={`gap-5 grid ${values.grid_props}`}>
      {values.cards?.map((card, i) => (
        <div className={`${values.border ? "border rounded-lg border-gray-200" : ""} p-5 flex flex-col gap-4`} key={i}>
          <div className="text-md font-semibold">{card.title[lang]}</div>
          <div className="rich-text text-xs font-poppins" dangerouslySetInnerHTML={{ __html: card.description[lang] }} />
        </div>
      ))}
    </div>
  );
};

const CardsForm = ({ values, onChange, lang }) => {
  const [number, setNumber] = useState(Number(values.grid_props?.split("-")[2]));

  const handleChange = (e) => {
    onChange({ ...values, [e.target.name]: e.target.value });
  };

  function addCard() {
    handleChange({
      target: {
        name: "cards",
        value: [
          ...values.cards,
          {
            title: { fr: "Carte", en: "Card" },
            description: { fr: "Description de la carte", en: "Card's description" },
          },
        ],
      },
    });
  }

  function removeCard(index) {
    handleChange({
      target: {
        name: "cards",
        value: values.cards.filter((_, i) => i !== index),
      },
    });
  }

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <label>Column number - {number}</label>
          <button
            onClick={() => {
              setNumber(number - 1);
              handleChange({
                target: {
                  name: "grid_props",
                  value: `grid-cols-${number - 1}`,
                },
              });
            }}
            disabled={number === 1}
            className={`w-6 h-6 rounded-lg ${number === 1 ? "gray-btn" : "blue-btn"}`}>
            -
          </button>
          <button
            onClick={() => {
              setNumber(number + 1);
              handleChange({
                target: {
                  name: "grid_props",
                  value: `grid-cols-${number + 1}`,
                },
              });
            }}
            disabled={number === 4}
            className={`w-6 h-6 rounded-lg ${number === 4 ? "gray-btn" : "blue-btn"}`}>
            +
          </button>
        </div>
        <div className="flex items-center gap-4">
          <label>Border {values.border}</label>
          <input
            className="w-5 h-5 border rounded-md cursor-pointer"
            type="checkbox"
            id="border"
            name="border"
            checked={values.border}
            onChange={(e) => {
              handleChange({
                target: {
                  name: "border",
                  value: e.target.checked,
                },
              });
            }}
          />
        </div>
      </div>
      <div>
        <div className={`gap-5 mt-8 grid ${values.grid_props}`}>
          {values.cards?.map((card, index) => (
            <CardsFormData
              key={index}
              card={card}
              onDelete={() => removeCard(index)}
              border={values.border}
              onChange={(newCard) => {
                const newCards = values.cards.map((c, i) => (i === index ? newCard : c));
                handleChange({
                  target: {
                    name: "cards",
                    value: newCards,
                  },
                });
              }}
              lang={lang}
            />
          ))}
          <button className={`p-4 border flex flex-col justify-center items-center rounded-md cursor-pointer`} onClick={addCard}>
            <FaPlus />
            <p className="text-md font-semibold">Add Card</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export const CardsFormData = ({ card, onChange, onDelete, border, lang }) => {
  return (
    <div className={`p-4 ${border ? "border rounded-lg border-gray-200" : ""} overflow-hidden relative`}>
      <div className={"flex flex-col mb-2"}>
        <div className="text-md font-semibold">Title</div>
        <input
          className="input"
          type="text"
          id="name"
          name="name"
          value={card.title[lang]}
          onChange={(e) => {
            onChange({ ...card, title: { ...card.title, [lang]: e.target.value } });
          }}
        />
      </div>

      <div className="text-xs font-poppins">
        <div className="text-md font-semibold">Description</div>
        <RichTextEditorSun
          buttonList={[
            ["bold", "underline", "italic", "strike"],
            ["list", "lineHeight"],
          ]}
          values={card.description[lang]}
          onChange={(e) => onChange({ ...card, description: { ...card.description, [lang]: e } })}
        />
      </div>

      <button className="gray-btn h-5 w-5 p-0 absolute top-1 right-1" onClick={onDelete}>
        <IoTrash />
      </button>
    </div>
  );
};

export default Cards;
