import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import "react-tagsinput/react-tagsinput.css";

import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";
import amplitude from "../../../services/amplitude";

import { readFileAsync } from "../../../utils";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Profile = () => {
  const navigate = useNavigate();
  const applicant = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch();

  const [experience, setExperience] = useState(applicant?.experience || "");
  const [linkedin, setLinkedin] = useState(applicant?.linkedin || "");
  const [github, setGithub] = useState(applicant?.github || "");
  const [other, setOther] = useState(applicant?.other || "");
  const [resume, setResume] = useState(applicant?.other || "");
  const [jobMotivation, setJobMotivation] = useState(applicant?.job_motivation || "");

  const id = decodeURI(new URLSearchParams(window.location.search).get("jobpost"));

  if (!applicant) {
    navigate(`/auth/signup?jobpost=${id}`);
    return null;
  }

  const onUpdate = async () => {
    try {
      const obj = { experience, linkedin, github, other, resume, job_motivation: jobMotivation };
      const { data, code, ok } = await api.put(`/user/${applicant._id}`, obj);
      if (!ok) return toast.error(code);
      if (data) dispatch(setUser(data));
      amplitude.logEvent("profile");

      await api.post("/application", { mission_id: applicant.job_id, mission_name: applicant.job_title, jobpost_id: applicant.jobpost_id });
      if (data) dispatch(setUser(data));
      toast.success("Account created successfully");

      navigate(`/applicant`);
    } catch (e) {
      console.log("e", e);
      toast.error("ERROR");
    }
  };

  return (
    <div className="flex flex-col pt-12 bg-light-white min-h-screen">
      <div className="w-full bg-dush-gray h-1">
        <div className="bg-shade-blue h-1 w-3/4"></div>
      </div>
      <div className="flex flex-col flex-1 max-w-3xl w-full mx-auto">
        <div className="w-eighty ml-20 mt-8 flex-1 pb-28">
          <div className="mb-6">
            <p className="text-dull-black text-3xl font-semibold mb-3">Your profile</p>
          </div>
          <div className="mb-6">
            <p className="text-dull-black text-xl font-semibold">
              Your level of experience<span className="text-red-600">*</span>
            </p>
            <div className="flex gap-5 mt-3">
              <ExperienceSelectOption year="0 to 2 years" level="Entry-level" selected={experience === "entry-level"} onClick={() => setExperience("entry-level")} />
              <ExperienceSelectOption year="2 to 7 years" level="Intermediate" selected={experience === "intermediate"} onClick={() => setExperience("intermediate")} />
              <ExperienceSelectOption year="7+ years" level="Expert" selected={experience === "expert"} onClick={() => setExperience("expert")} />
            </div>
          </div>

          <div className="mb-6 ">
            <p className="text-dull-black text-xl font-semibold mb-2">Your socials</p>
            <div className="text-dull-black text-base	font-semibold mb-1 flex items-center gap-1">
              <FaLinkedin className="text-blue-600 text-lg" />
              <p>
                Linkedin<span className="text-red-600">*</span>
              </p>
            </div>
            <input
              className="bg-transparent outline-0	block w-full p-2.5 mb-1.5	rounded-lg text-sm font-medium pl-3 border border-gray-200"
              type="text"
              placeholder="Enter your Linkedin URL"
              value={linkedin}
              onChange={(e) => setLinkedin(withHttp(e.target.value))}
            />
            <div className="text-dull-black text-base	font-semibold mb-1 flex items-center gap-1">
              <FaGithub className="text-black text-lg" />
              <p>Github</p>
            </div>
            <input
              className="bg-transparent outline-0	block w-full p-2.5 mb-1.5	rounded-lg text-sm font-medium pl-3 border border-gray-200"
              type="text"
              placeholder="Enter your Linkedin URL"
              value={github}
              onChange={(e) => setGithub(withHttp(e.target.value))}
            />
            <p className="text-dull-black text-base	font-semibold mb-1">Other</p>
            <input
              className="bg-transparent outline-0	block w-full p-2.5 mb-1.5	rounded-lg text-sm font-medium pl-3 border border-gray-200"
              type="text"
              placeholder="Portfolio, website, blog, etc."
              value={other}
              onChange={(e) => setOther(withHttp(e.target.value))}
            />
            <p className="text-dull-black text-base	font-semibold mb-1">
              Resume<span className="text-red-600">*</span>
            </p>
            <div>
              <label className="flex items-center justify-between space-x-2 w-72 p-2.5 mb-2 rounded-lg pl-3 border border-gray-200 cursor-pointer">
                <div className=" flex items-center gap-2">
                  <MdUpload className="text-black text-lg" />
                  <span className="text-sm">Upload resume</span>
                </div>
                {resume ? <BsCheckCircleFill className="text-green-600 text-lg" /> : <BsCheckCircle className="text-gray-500 text-lg" />}
                <input
                  type="file"
                  hidden
                  onChange={async (e) => {
                    const f = e.target.files[0];
                    const rawBody = await readFileAsync(f);
                    const { data } = await api.post(`/file`, { file: { rawBody, name: f.name }, folder: "/documents" });
                    setResume(data);
                  }}
                />
              </label>
              {resume ? (
                <div className="flex gap-5">
                  <a href={resume} className="text-sm underline mt-1">
                    {"Resume uploaded"}
                  </a>
                  <MdDelete className="text-black text-lg mt-1 cursor-pointer" onClick={() => setResume("")} />
                </div>
              ) : null}
            </div>
            <div className="">
              <p className="text-dull-black text-xl font-semibold mb-2">Your motivations</p>
              <textarea
                className="bg-transparent outline-0	block w-full p-2.5 mb-1.5	rounded-lg text-sm font-medium pl-3 border border-gray-200"
                placeholder="Describe how you are the best fit for this job..."
                value={jobMotivation}
                onChange={(e) => setJobMotivation(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full md:w-1/2 fixed bottom-0 justify-between mt-20 px-20 py-6 border-t-[1px] border-gray-100 bg-white/10 backdrop-blur">
          <LoadingButton onClick={navigate.goBack} className={`bg-white w-32 border !border-chips-text text-chips-text rounded-large font-medium text-base p-[8px] min-h-[42px]`}>
            Previous
          </LoadingButton>
          <LoadingButton
            disabled={!experience || !linkedin || !resume}
            onClick={onUpdate}
            className="w-32 bg-chips-text hover:bg-shade-sky-blue text-white rounded-large font-medium text-base p-[8px] min-h-[42px]">
            Apply
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default Profile;

const ExperienceSelectOption = ({ year, level, onClick, selected }) => {
  return (
    <>
      <div
        onClick={onClick}
        className={`w-2/5 bg-half-white flex flex-col justify-center items-center py-2 px-3 rounded-large cursor-pointer border-2 ${
          selected ? "!border-shade-blue" : "border-light-white"
        } `}>
        <p className="text-sm font-semibold text-dull-black">{year}</p>
        <span className="text-light-grey text-sm font-medium">{level}</span>
      </div>
    </>
  );
};

const withHttp = (url) => {
  return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
};
